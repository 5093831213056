<template>
  <section class="pb-8" id="contact">
    <v-container fluid class="pt-12 pb-12">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <h1 class="font-weight-light display-1">Kontakt</h1>
          <h3 class="font-weight-light mt-3">
          </h3>
          <h3 class="font-weight-light mt-3">
            Kontaktieren Sie uns mit einer unverbindlichen Anfrage. Wir kommen zeitnah auf Sie zu und besprechen das
            weitere Vorgehen.
          </h3>
          <h3 class="font-weight-light mt-3">
            Telefon: +49 163 1290699
          </h3>
          <h3 class="font-weight-light">
            Email: <a href="mailto:info@schrudde-wegener.de">info@schrudde-wegener.de</a>
          </h3>
          <!--<v-row justify="center">
            <v-col cols="12" sm="10">

            </v-col>
            <v-col cols="12" sm="7">
              <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                <v-text-field v-model="name" :rules="nameRules" label="Name" required></v-text-field>
                <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
                <v-textarea v-model="textArea" :rules="textAreaRules" label="Nachricht" required/>
                <v-btn :disabled="!valid" color="primary" :dark="valid" rounded block class="mt-3" @click="submit">
                  Absenden
                </v-btn>
              </v-form>
            </v-col>
          </v-row>-->
        </v-col>
      </v-row>
    </v-container>
    <div class="svg-border-waves text-white">
      <v-img src="~@/assets/img/borderWavesBlue.svg"/>
    </div>
    <v-snackbar v-model="snackbar.enabled" timeout="3000" right top :color="snackbar.color">
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.enabled = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>

<style scoped>
#contact {
  background-color: #f4f7f5;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

</style>

<script>
import {sendMessage} from "@/main.js";

export default {
  data: () => ({
    icons: ["fa-facebook", "fa-twitter", "fa-linkedin", "fa-instagram"],
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "Wir benötigen Ihren Namen zur Bearbeitung der Anfrage",
      (v) => (v && v.length >= 6) || "Ein gültiger Name muss mindestens 6 Buchstaben besitzen",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "Damit wir Sie kontaktieren können ist eine E-Mail-Adresse notwendig",
      (v) => /.+@.+\..+/.test(v) || "Die E-Mail-Adresse muss ein gültiges Format haben. Die angegebene Adresse ist ungültig.",
    ],
    textArea: "",
    textAreaRules: [
      (v) => !!v || "Bitte beschreiben Sie Ihr Anliegen etwas ausführlicher.",
      (v) => (v && v.length >= 10) || "Bitte beschreiben Sie Ihr Anliegen etwas ausführlicher.",
    ],
    lazy: false,
    snackbar: {
      enabled: false,
      text: '',
      color: ''
    }
  }),
  methods: {
    submit() {
      sendMessage({
        name: this.name,
        contact: this.email,
        message: this.textArea
      }).then(value => {
        console.debug(value.data);
      }).catch(reason => {
        console.error("service unavailable. Suggested handling: send mail with your client the usual way.")
        window.location = "mailto:info@schrudde-wegener.de?subject=Anfrage&body=" + this.textArea;
      })
    }
  }
};
</script>
