import Vue from 'vue'
import App from './App.vue'
import {initializeApp} from "firebase/app";
import Vuetify from 'vuetify/lib';
import {getFunctions, httpsCallable} from 'firebase/functions';

Vue.use(Vuetify);

const firebaseConfig = {
    apiKey: "AIzaSyCQMfhIo4hg3_wDMo17f6mzzi8EuOSJA6Y",
    authDomain: "schrudde-wegener-service-97525.firebaseapp.com",
    projectId: "schrudde-wegener-service-97525",
    storageBucket: "schrudde-wegener-service-97525.appspot.com",
    messagingSenderId: "1026201162581",
    appId: "1:1026201162581:web:8b47fdbecce1ef1266d5dc",
    measurementId: "G-EQC5556VDV"
};

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

export const sendMessage = httpsCallable(functions, 'sendMessage');

Vue.config.productionTip = false

new Vue({
    vuetify: new Vuetify(),
    render: h => h(App),
}).$mount('#app')